import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)


export const userstore = new Vuex.Store({
  state: {
    id: null,
    firstName: "",
    lastName: "",
    draftquestionaire: "",
    draftanswers: [],
    lang: 'en',
    needsReset: null,
    backurl: "test.bd4qolfit.eu",
    currentQuestionnaires: [],
    submissionDate: new Date(),
    isClinician: false,
  },
  plugins: [createPersistedState()],
  mutations: {
    changeId(state, newId) {
      state.id = newId;
    },
    changeneedsReset(state, newNeedsReset) {
      state.needsReset = newNeedsReset;
    },
    changefirstName(state, newfirstName) {
      state.firstName = newfirstName;
    },
    changelastName(state, newlastName) {
      state.lastName = newlastName;
    },
    changeDraftQuestionnaire(state, newdraftquestionnaire) {
      state.draftquestionaire = newdraftquestionnaire;
    },
    changeDraftAnswers(state, newdraftanswers) {
      state.draftanswers = newdraftanswers;
    },
    changeSubmissionDate(state, submissionDate) {
      state.submissionDate = submissionDate;
    },
    changeIsClinician(state, isClinician) {
      state.isClinician = isClinician;
    },
    changeLang(state, newLang) {
      state.lang = newLang;
      window.location.reload();
    },
    changeCurrentQuestionnaires(state, newcurrentQuestionnaires) {
      var questionables = [];
console.log(newcurrentQuestionnaires);
newcurrentQuestionnaires = Object.values(newcurrentQuestionnaires);
console.log(newcurrentQuestionnaires);
      for (var i = 0; i < newcurrentQuestionnaires.length; i++) {
        questionables
          .push({ questionable_type: newcurrentQuestionnaires[i].questionable_type, questionable_id: newcurrentQuestionnaires[i].questionable_id });
      }
      state.currentQuestionnaires = questionables;
      console.log("questionables - " + questionables.toString());
    },
  },

})
